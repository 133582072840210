import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../Store/Actions/type";
import * as actionCreater from "../../Store/Actions/EventActions";
import LocationModal from "../Common/Location/LocationModal";
import UserSelect from "../User/UserSelect";

import {
    Button,
    ModalFooter,
    ModalBody,
    ModalHeader,
    Modal
} from 'reactstrap';

class EditEvent extends Component {
    initState = {
        ...this.props,
        processing: false,
        isOpen: false,
        isLoading: true,
        id: this.props.eventData.id,
        files: [],


        PublicStatus : null, 
        address : null, 
        big_blind : null, 
        date : null, 
        game_profile_string : null,
        game_type_string : null, 
        max_buyins : null, 
        max_players : null, 
        min_buyins : null,
        min_players : null, 
        name : null, 
        purchase_amount : null, 
        re_buyins : null, 
        small_blind : null, 
        status : null,
        table_rules : null, 
        time : null, 
        valid_start_date : null, 
        valid_end_date : null, 
        votingEnabled : null,
        users : null,
    };

    state = {
        ...this.initState
    };

    getFiles(files) {
        this.setState({ file_1: files })
    }

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    };

    onChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };



    eventDetail = (id) => {

        this.setState({
            isLoading: true
        });

        let { eventDetail, user, errorHandler } = {...this.props};

        eventDetail(user.auth.access_token, id).then(res => {

            console.log("Res = > ", res.data.data);

            this.setState({
                ...res.data.data
            });

        }).catch(errorHandler).finally(() => {
            this.setState({
                isLoading: false
            });
        });
    };


    componentDidMount() {
        this.eventDetail(this.state.id);
    };

    editEvent = () => {

        this.setState({
            processing: true
        });

        let { user, editEvent, dispatch, alertify, errorHandler } = this.props;

        let {
            id, PublicStatus, address, big_blind, date, game_profile_string, 
            game_type_string, max_buyins, max_players, home_payment, chips_per_dollar, min_buyins, 
            min_players, name, purchase_amount, re_buyins, small_blind, status, 
            table_rules, time, valid_start_date, valid_end_date, votingEnabled,
            users, } = this.state;

        const params = {
            id, name, game_type: game_type_string, isPrivate: PublicStatus, game_date: date, 
            game_profile: game_profile_string, votingEnabled, time, status, re_buyins, 
            small_blind, big_blind, home_payment, chips_per_dollar, min_buyins, max_buyins, purchase_amount, min_players, 
            max_players, table_rules, address, valid_start_date, valid_end_date, users: users.map(u => u.id)
        };

        editEvent(user.auth.access_token, id, params).then(res => {

            dispatch({
                type: actions.EDIT_EVENT,
                payload: res.data.data
            });

            this.setState({
                isOpen: false
            });

            alertify.success(res.data.message);

        }).catch(errorHandler).finally(() => {
            this.setState({
                processing: false
            });
        });
    };

    renderLocation = (isHead = true, model = null) => {
        return <LocationModal centerAroundCurrentLocation onLocationChange={l => { this.setState({ location: l }) }} style={{ width: '85%', height: '90%' }} modalStyle={{ width: '100%', height: '400px' }} />
    }
    Tournamentfields = () => {
        let { isLoading } = this.state;
        if (isLoading) {
            return false;
        }
        let { purchase_amount, re_buyins, game_type_string: m } = this.state;
        if (m === "tournament" || m === "cash/tournament") {
            return (
                <React.Fragment>
                    <div className="form-group row">
                        <label for="purchase_amount" className="col-sm-3 col-form-label">Purchase Amount</label>
                        <div className="col-sm-9">
                            <input type="number" className="form-control pull-left" name="purchase_amount" value={purchase_amount} onChange={this.onChange} id="purchase_amount" placeholder="Please Enter Purchase Amount" />
                        </div>
                    </div>
                    <div className="form-group row op-cash">
                        <label for="re_buyins" className="col-sm-3 col-form-label">Re Buyin's</label>
                        <div className="col-sm-9">
                            <input type="number" className="form-control pull-left" name="re_buyins" value={re_buyins} onChange={this.onChange} id="re_buyins" placeholder="Please Enter Re Buyin's" />
                        </div>
                    </div>
                </React.Fragment>
            )
        }
    }
    cashfields = () => {
        let { isLoading } = this.state;
        if (isLoading) {
            return false;
        }
        let {
            small_blind, big_blind,
            min_buyins, max_buyins, game_type_string: m } = this.state;
        if (m === "cash" || m === "cash/tournament") {
            return (
                <React.Fragment>
                    <div className="form-group row">
                        <label for="small_blind" className="col-sm-3 col-form-label">Small Blind</label>
                        <div className="col-sm-9">
                            <input type="number" className="form-control pull-left" name="small_blind" value={small_blind} onChange={this.onChange} id="small_blind" placeholder="Please Enter Small Blind" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label for="big_blind" className="col-sm-3 col-form-label">Big Blind</label>
                        <div className="col-sm-9">
                            <input type="number" className="form-control pull-left" name="big_blind" value={big_blind} onChange={this.onChange} id="big_blind" placeholder="Please Enter Big Blind" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label for="min_buyins" className="col-sm-3 col-form-label">Buyin's</label>
                        <div className="col-sm-9">
                            <input type="number" className="form-control pull-left" name="min_buyins" value={min_buyins} onChange={this.onChange} id="min_buyins" placeholder="Please Enter Min Buyin's" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label for="max_buyins" className="col-sm-3 col-form-label">Max Buyin's</label>
                        <div className="col-sm-9">
                            <input type="number" className="form-control pull-left" name="max_buyins" value={max_buyins} onChange={this.onChange} id="max_buyins" placeholder="Please Enter Max Buyin's" />
                        </div>
                    </div>
                </React.Fragment>
            )
        }
    }
    privatefields = () => {
        let { isLoading } = this.state;
        if (isLoading) {
            return false;
        }
        let {
            valid_start_date, valid_end_date, votingEnabled, users: u,
            PublicStatus: m } = this.state;


        if (m === "Private") {
            return (
                <React.Fragment>
                    <div className="form-group row">
                        <label for="valid_start_date" className="col-sm-3 col-form-label">Avaliable Start Date</label>
                        <div className="col-sm-9">
                            <input type="date" className="form-control pull-left" name="valid_start_date" value={valid_start_date} onChange={this.onChange} id="valid_start_date" placeholder="Please Select valid Start Date" />
                        </div>
                    </div>
                    <div className="form-group row ">
                        <label for="valid_end_date" className="col-sm-3 col-form-label">Avaliable End Date</label>
                        <div className="col-sm-9">
                            <input type="date" className="form-control pull-left" name="valid_end_date" value={valid_end_date} onChange={this.onChange} id="valid_end_date" placeholder="Please Select valid End Date" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label for="votingEnabled" className="col-sm-3 col-form-label">Voting</label>
                        <div className="col-sm-9">
                            <select name="votingEnabled" value={votingEnabled} onChange={this.onChange} id="votingEnabled" className="form-control pull-left" >
                                <option value="1">Enabled</option>
                                <option value="0">Disabled</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label for="users" className="col-sm-3 col-form-label">Players</label>
                        <div className="col-sm-9">
                            <UserSelect

                                isMulti
                                {...this.props}
                                defaultValue={(u) ? u.map(m => ({ value: m.id, label: m.nick_name })) : []}
                                onChange={users => this.setState({ users })}
                            placeholder="Please Select Players you want to add fot this event"
                            search="users"
                        />
                        </div>
                    </div>
                </React.Fragment>
            )
        }
    }
    render() {

        
        let { processing, isOpen, isLoading } = this.state;

        if (isLoading) {
            return false;
        }

        let {
            name, game_type_string, PublicStatus, date, address, game_profile_string, time,
            min_players, max_players, table_rules, status, home_payment, chips_per_dollar } = this.state;

        console.log('data=>', this.state);

        return (
            <React.Fragment>

                <button type="button" onClick={this.toggle} className="dropdown-item" > <i className="fas fa-edit"> </i> Edit</button>

                <Modal isOpen={isOpen} toggle={this.toggle} className="modal-primary modal-lg">

                    <ModalHeader toggle={this.toggle}><i className="fa fa-edit"></i>  Edit Event</ModalHeader>

                    <ModalBody>
                        <div className="main-content-container container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group row">
                                        <label for="name" className="col-sm-3 col-form-label">Name</label>
                                        <div className="col-sm-9">
                                            <input type="text" className="form-control pull-left" name="name" value={name} onChange={this.onChange} id="name" placeholder="Please Enter Event Name" />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label for="game_profile_string" className="col-sm-3 col-form-label">Game Profile</label>
                                        <div className="col-sm-9">
                                            <select name="game_profile_string" value={game_profile_string} onChange={this.onChange} id="game_profile" className="form-control pull-left" >
                                                <option value="texas_holdem">Texas Holdem</option>
                                                <option value="dealer_choice">Dealer Choice</option>
                                                <option value="omaha">Omaha</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label for="status" className="col-sm-3 col-form-label">Event Status</label>
                                        <div className="col-sm-9">
                                            <select name="status" value={status} onChange={this.onChange} id="status" className="form-control pull-left" >
                                                <option value="not_started">Not Started</option>
                                                <option value="pending_voting">Pending Voting</option>
                                                <option value="voting_finalized">Voting Finalized</option>
                                                <option value="started">Started</option>
                                                <option value="completed">Completed</option>
                                                <option value="canceled">Canceled</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label for="game_type_string" className="col-sm-3 col-form-label">Game Type</label>
                                        <div className="col-sm-9">
                                            <select name="game_type_string" value={game_type_string} onChange={this.onChange} id="game_type_string" className="form-control pull-left" >
                                                <option value="cash">Cash</option>
                                                <option value="tournament">Tournament</option>
                                                <option value="cash/tournament">Cash/ Tournament</option>
                                            </select>
                                        </div>
                                    </div>
                                    {this.Tournamentfields()}
                                    {this.cashfields()}
                                    <div className="form-group row">
                                        <label for="PublicStatus" className="col-sm-3 col-form-label">Event Type</label>
                                        <div className="col-sm-9">
                                            <select name="PublicStatus" value={PublicStatus} onChange={this.onChange} id="PublicStatus" className="form-control pull-left" >
                                                <option value="Public">Public</option>
                                                <option value="Private">Private</option>
                                            </select>
                                        </div>
                                    </div>
                                    {this.privatefields()}
                                    <div className="form-group row">
                                        <label for="date" className="col-sm-3 col-form-label">Date</label>
                                        <div className="col-sm-9">
                                            <input type="datetime-local" className="form-control pull-left" name="date" value={date} onChange={this.onChange} id="date" placeholder="Please Select Date" />
                                        </div>
                                    </div>
                                    {/* <div className="form-group row">
                                        <label for="Time" className="col-sm-3 col-form-label">Time</label>
                                        <div className="col-sm-9">
                                            <input type="datetime-local" className="form-control pull-left" name="time" value={time} onChange={this.onChange} id="time" placeholder="Please Select Time" />
                                        </div>
                                    </div> */}
                                    <div className="form-group row">
                                        <label for="chips_per_dollar" className="col-sm-3 col-form-label">Chips/One Dollar</label>
                                        <div className="col-sm-9">
                                            <input type="number" className="form-control pull-left" name="chips_per_dollar" value={chips_per_dollar} onChange={this.onChange} id="min_players" placeholder="Please Enter Chips Per 1$" />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label for="home_payment" className="col-sm-3 col-form-label">House Admission</label>
                                        <div className="col-sm-9">
                                            <input type="number" className="form-control pull-left" name="home_payment" value={home_payment} onChange={this.onChange} id="min_players" placeholder="Please Enter House Admission Fee" />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label for="min_players" className="col-sm-3 col-form-label">Minimum Players</label>
                                        <div className="col-sm-9">
                                            <input type="number" className="form-control pull-left" name="min_players" value={min_players} onChange={this.onChange} id="min_players" placeholder="Please Enter Minimum No. of Players" />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label for="max_players" className="col-sm-3 col-form-label">Maximum Players</label>
                                        <div className="col-sm-9">
                                            <input type="number" className="form-control pull-left" name="max_players" value={max_players} onChange={this.onChange} id="max_players" placeholder="Please Enter Maximum No. of Players" />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <label for="table_rules" className="col-sm-3 col-form-label">Table Rules</label>
                                        <div className="col-sm-9">
                                            <textarea name="table_rules" id="table_rules" className="form-control pull-left" value={table_rules} onChange={this.onChange} placeholder="Please Enter Table Rules Here..." rows="4"></textarea>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label for="address" className="col-sm-3 col-form-label">Address</label>
                                        <div className="col-sm-9">
                                            <textarea name="address" id="address" className="form-control pull-left" value={address} onChange={this.onChange} placeholder="Please Enter Address" rows="4"></textarea>
                                        </div>
                                    </div>
                                    
                                    
                                </div>
                            </div>
                        </div>
                    </ModalBody>

                    <ModalFooter>

                        <Button color="secondary" onClick={this.toggle}>Close</Button>
                        <Button color="danger" onClick={this.editEvent}>{(processing) ? "Updating..." : "Update"}</Button>{' '}

                    </ModalFooter>

                </Modal>
            </React.Fragment>
        )
    }
}
const mapStateToProps = state => {
    return {
        metaData: state.MetaDataReducer,
        events: state.EventReducer.events,
    };
};
const mapDispatchToProps = () => {
    return {
        eventDetail: (token, id) => actionCreater.eventDetail(token, id),
        editEvent: (token, id, data) => actionCreater.editEvent(token, id, data),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditEvent);