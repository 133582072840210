import APIModel from "../../Models/APIModel";
import axios from "axios";

export const getSearches = (token, search) => {
  return axios.get(APIModel.HOST + "admin/searches" + search, {
    'headers': {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  });
};