import React, { Component } from "react";
import DeleteModal from "../Common/Modals/DeleteModal";
import { connect } from "react-redux";
import * as qs from "query-string";
import SimplePagination from "../Common/SimplePagination";
import * as actions from "../../Store/Actions/type";
import * as action from "../../Store/Actions/SearchActions";
import { getSearchUrlFromState } from "../../util/functions";
import {
  Col, Input, InputGroup, InputGroupAddon,
  Button,
} from "reactstrap";

import { Link } from "react-router-dom";

class Searches extends Component {
  state = {
    searches: []
  };


  get = (search) => {
    this.setState({
      isLoading: true
    });
    let { get, dispatch, user, errorHandler } = this.props;

    get(user.auth.access_token, search)
      .then(res => {
        this.setState({
          page: res.data.meta.current_page,
          totalPages: res.data.meta.last_page
        });

        dispatch({
          type: actions.GET_SEARCHES,
          payload: res.data.data,
        });

        this.setState({
          searches: res.data.data,
        });
        
      })
      .catch(errorHandler)
      .finally(() => {
        this.setState({
          isLoading: false
        });
      });
  };

  componentDidMount() {
    let search = this.props.location.search;

    const params = qs.parse(search);

    for (let key in params) {
      this.setState({
        [key]: params[key]
      });
    }

    this.get(search);
  }
  next = () => {
    let next = this.state.page + 1;
    if (next <= this.state.totalPages) {
      let search = getSearchUrlFromState(this.state);
      this.get(search + "page=" + next);
    }
  };

  previous = () => {
    let previous = this.state.page - 1;
    if (previous > 0) {
      let search = getSearchUrlFromState(this.state);
      this.get(search + "page=" + previous);
    }
  };

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  filter = () => {
    let search = getSearchUrlFromState(this.state);

    this.get(search);
  };

  renderBody = () => {
    if (this.state.isLoading) {
      return;
    }
    
    const { searches } = this.state;

    return searches.map(m => {
      return (
        <tr key={m.id}>
          <td> {m.id} </td>
          <td> {m.value} </td>
          <td> {m.user.first_name + " " + m.user.last_name} </td>
          <td> {m.created_at} </td>
          <td> </td>
        </tr>
      );
    });
  };


  render() {
    return (
      <div className="main-content-container container-fluid px-4">
        {/* Page Header  */}
        <div className="page-header row no-gutters py-4">
          <div className="col-md-3 mb-0">
            <h4 className="page-title black">Displaying Searches List</h4>
          </div>
          <Col className="col-md-8 px-2">
            <InputGroup>
              <Input type="text" placeholder="Filter Queries" name="value" onChange={this.onChange} value={this.state.value} />
              <InputGroupAddon addonType="append">
                <Button type="button" color="primary" onClick={this.filter}><i className="fa fa-filter" /> Filter</Button>
              </InputGroupAddon>
            </InputGroup>
          </Col>
        </div>
        <div className="row">
          <div className="col-lg-12 mb-4">
            <div className="table-responsive custom-tables">
              <table className="table table-hover table-rounded table-users">
                <thead>
                  <tr>
                    <th>Index</th>
                    <th>Query</th>
                    <th>User</th>
                    <th>Date</th>
                    <th> <i className="fas fa-list mr-2" />Actions </th>
                  </tr>
                </thead>
                <tbody>{this.renderBody()}</tbody>
              </table>
              <SimplePagination next={this.next} previous={this.previous} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    searches: state.SearchReducer.searches,
  };
};

const mapDispatchToProps = () => {
  return {
    get: (token, search) => action.getSearches(token, search),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Searches);
