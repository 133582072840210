export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const FORGET_PASSWORD = "FORGET_PASSWORD";

export const GET_BLOCKFEATURES = "GET_BLOCKFEATURES";
export const CREATE_BLOCKFEATURE = "CREATE_BLOCKFEATURE";
export const EDIT_BLOCKFEATURE = "EDIT_BLOCKFEATURE";
export const DELETE_BLOCKFEATURE = "DELETE_BLOCKFEATURE";

export const GET_CONFIGURATIONS = "GET_CONFIGURATIONS";
export const CREATE_CONFIGURATION = "CREATE_CONFIGURATION";
export const EDIT_CONFIGURATION = "EDIT_CONFIGURATION";
export const DELETE_CONFIGURATION = "DELETE_CONFIGURATION";

export const GET_EVENTS = "GET_EVENTS";
export const CREATE_EVENT = "CREATE_EVENT";
export const GET_EVENTCONFIGURATION = "GET_EVENTCONFIGURATION";
export const EDIT_EVENT = "EDIT_EVENT";
export const DELETE_EVENT = "DELETE_EVENT";

export const GET_USERS = "GET_USERS";
export const CREATE_USER = "CREATE_USER";
export const EDIT_USER = "EDIT_USER";
export const EDIT_USER_PROFILE = "EDIT_USER_PROFILE";
export const DELETE_USER = "DELETE_USER";
export const USER_DETAIL = "USER_DETAIL";


export const GET_REVIEW = "GET_REVIEW";
export const CREATE_REVIEW = "CREATE_REVIEW";
export const EDIT_REVIEW = "EDIT_REVIEW";
export const DELETE_REVIEW = "DELETE_REVIEW";


export const GET_META_DATA = "GET_META_DATA";
export const GET_SETTINGS = "GET_SETTINGS";
export const EDIT_SETTINGS = "EDIT_SETTINGS";

export const CREATE_PUSH_NOTIFICATION = "CREATE_PUSH_NOTIFICATION";
export const GET_USER_GROWTH = "GET_USER_GROWTH";
export const GET_EVENT_STATS = "GET_EVENT_STATS";
export const GET_DASHBOARD = "GET_DASHBOARD";
export const GET_USER_PER_COUNTRY = "GET_USER_PER_COUNTRY";

export const GET_SEARCHES = "GET_SEARCHES";
export const GET_COUNTRIES = "GET_COUNTRIES";
