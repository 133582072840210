class APIModel {

  // static HOST = "http://localhost/MpsetLaravel/public/api/v1/en/";
  // static URL = "http://localhost/MpsetLaravel/public/";


  // static HOST = "http://127.0.0.1:80/api/v1/en/";
  // static HOST = "http://192.168.10.50/api/v1/en/";


  // static URL = "http://192.168.10.60:8080/~saad/mpset/public";
  // static HOST = "http://192.168.10.60:8080/~saad/mpset/public/api/v1/en/";

  // //for server
  static URL = "https://backend.mpset.co/"; 
  static HOST = "https://backend.mpset.co/api/v1/en/";

  // for sr7 server 
  // static URL = "http://sr7tech.ga:8080/~saad/mpset/MPSET_backend/public/"
  // static HOST = "http://sr7tech.ga:8080/~saad/mpset/MPSET_backend/public/api/v1/en/";

}
export default APIModel;