import * as actions from "../Actions/type";

const initSate = {
  features: [],
};

const MetaDataReducer = (state = initSate, action) => {

  switch (action.type) {

    case actions.GET_META_DATA: {

      const { features } = action.payload;

      return {
        ...state,
        features
      };
    }

    case actions.GET_COUNTRIES: {
console.log('reducer:' , action.payload);
      return { ...state, countries: action.payload };
    }

    default:
      return state;
  }
  
};

export default MetaDataReducer;
