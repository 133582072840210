import React, { Component } from "react";
import { connect } from "react-redux";
import * as qs from 'query-string';
import UserSelect from "../User/UserSelect"
import CountrySelect from "../CountriesSelect"
import * as actions from "../../Store/Actions/type";
import * as actionCreater from "../../Store/Actions/PushNotificationActions";
import { countries } from 'country-data';
import ReactFlagsSelect from 'react-flags-select';
import 'react-flags-select/css/react-flags-select.css';

class createPushNotification extends Component {

    initState = {

        title: "",
        body: "",
        payload: {},
        allUsers: false,
        user_id: "",
        users: [],
        countries: [],
        processing: false,
        isOpen: false,
        country: "",
        country_code: "",
    }

    state = {
        ...this.initState
    };

    componentDidMount() {

        let search = this.props.location.search;
        const params = qs.parse(search);
        const { id, name } = params;

        if ((id) && (name)) {
            this.setState({
                users: [
                    { id, first_name: name }
                ]
            })
        }

    }

    onChange = e => {
        this.setState({
            [e.target.name]: (e.target.avatar) ? e.target.avatar[0] : e.target.value
        });
    };

    create = () => {

        this.setState({
            processing: true
        });

        let { createPushNotification, dispatch, alertify, user, errorHandler} = this.props;

        let { title, body, countries, users, country_code,country } = { ...this.state };

        let params = { title, body, countries, users, country_code,country };

        createPushNotification(user.auth.access_token, params).then(res => {

            dispatch({
                type: actions.CREATE_PUSH_NOTIFICATION,
                payload: res.data.data
            });

            this.setState({ ...this.initState });

            alertify.success(res.data.message);

        }).catch(errorHandler).finally(() => {
            this.setState({
                processing: false
            });
        });

    };

    renderAudiance = () => {
        const { users } = this.state;

        return (
            <div className="form-group row">
                <label for="users" className="col-sm-2 col-form-label">Audience</label>
                <div className="col-sm-10">
                    <UserSelect
                        {...this.props}
                        isMulti

                        users={users}
                        onChange={users => this.setState({ users })}
                        placeholder="Please Select Users to Send Notification"

                    />
                </div>
            </div>
        );
    }

    renderCountries = () => {
        const { countries } = this.state;

        return (
            <div className="form-group row">
                <label for="users" className="col-sm-2 col-form-label">Countries</label>
                <div className="col-sm-10">
                    <CountrySelect
                        {...this.props}
                        isMulti

                        countries={countries}
                        onChange={countries => this.setState({ countries })}
                        placeholder="Please Select Countries to Send Notification"

                    />
                </div>
            </div>
        );
    }

    render() {
        // Edited
        // let { title, body, users, allUsers, processing } = this.state;
        let { title, body, processing, country_code,country } = this.state;

        const { label } = this.props;

        return (
            <div className="main-content-container container-fluid px-4">
                {/*  Page Header  */}
                <div className="page-header row no-gutters py-4">
                    <div className="col-12 col-sm-4 text-center text-sm-left mb-0">
                        <h3 className="page-title">Add Notification</h3>
                    </div>
                </div>
                {/* <!-- End Page Header --> */}
                {/* <!-- / Start Main Content --> */}
                <div className="row">
                    <div className="col-lg-12">
                        <div className="form-features pt-5 pb-4 mb-4">

                            <div className="form-group row">
                                <label for="title" className="col-sm-2 col-form-label">Title</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control pull-left" name="title" value={title} onChange={this.onChange} placeholder="Please Enter Notification Title" />
                                </div>
                            </div>


                            <div className="form-group row">
                                <label for="body" className="col-sm-2 col-form-label">Body</label>
                                <div className="col-sm-10">
                                    <textarea name="body" id="body" className="form-control pull-left" value={body} onChange={this.onChange} placeholder="Please Enter Notification Body" rows="4"></textarea>
                                </div>
                            </div>

                            {this.renderAudiance()}

                            {this.renderCountries()}

                            {/* <div className="form-group row">
                                <label for="Country" className="col-sm-2 col-form-label">Country</label>
                                <div className="col-sm-10">
                                    <ReactFlagsSelect
                                        searchPlaceholder="Search for a country"
                                        searchable={true}
                                        onSelect={country_code => this.setState({
                                            country_code,
                                            country: countries[country_code].name
                                        })} />
                                </div>
                            </div> */}

                            <div className="form-group row ">
                                <div className="col-12 text-center">
                                    <button className="btn btn-primary" onClick={this.create}>{(processing) ? "Sending..." : "Send"}</button>{' '}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapDispatchToProps = () => {
    return {
        createPushNotification: (token, params) => actionCreater.createPushNotification(token, params),
    };
};

export default connect(
    null,
    mapDispatchToProps
)(createPushNotification);