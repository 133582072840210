import * as actions from "../Actions/type";

const initSate = {
    searches : []
};

const SearchesReducer = (state = initSate, action) => {
  switch (action.type) {
    case actions.GET_SEARCHES: { 
      return { ...state, searches: action.payload };
    }
    default:{
      return state;
    }
  }
};

export default SearchesReducer;
